import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { InputTemplate } from '@tradesorg/component-library-waas-templates.base-input-template';




const SearchArea = ({handleKeyword, ...props }) => {
    const [question, setQuestion] = useState("");

    

    

    useEffect(() => {
        
        handleKeyword(question)
    }, [question]);

    return (
        <div
            className={`faqBackgroundWrapper bg-cover bg-center bg-no-repeat h-400px w-full absolute top-0 left-50% -translateX-50% ${styles.faqBackgroundWrapper}`}
            style={{ backgroundImage: `url(${require('@assets/images/search-area.png')})` }}
            {...props}
        >
            <h1
                className={`title ${styles.title} text-3xl lg:text-5xl pt-108px md-1:pt-168px text-center not-italic`}
            >
                How can we help?
            </h1>

            <div className={`mx-20px md-1:mx-auto mt-22px md-1:mt-30px max-w-570px ${styles.swapperInput}`}>
                <InputTemplate
                    classNames={{ input: 'border-0-important rounded-xl', rightComponent: 'xs:pointer-events-auto' }}
                    name="question"
                    id="question"
                    value={question}
                    onChange={(e) => {
                        setQuestion(e.target.value);
                    }}
                
                    placeholder="What do you want to ask us?"
                    style={{ height: '70px', backgroundColor: '#F3F6FB' }}
                    LeftComponent={
                        <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.2625 11.294C3.525 11.294 1.30607 9.06009 1.30607 6.30545C1.30607 3.54974 3.525 1.31581 6.2625 1.31581C9 1.31581 11.2189 3.54974 11.2189 6.30545C11.2189 9.06009 9 11.294 6.2625 11.294ZM15 14.069L11.145 10.1883C11.4911 9.74474 11.7836 9.24545 12.0086 8.69795C13.3179 5.51367 11.8446 1.86117 8.7 0.496166C7.92964 0.167237 7.10036 -0.000975609 6.2625 9.53674e-05C2.80179 0.00116634 -0.00214163 2.82652 1.22741e-06 6.30974C0.00107266 8.84152 1.5075 11.1279 3.82393 12.1147C6.04929 13.0415 8.50072 12.5819 10.2321 11.1322L14.0754 15.0001L15 14.069Z"
                                fill="#6E4BD9"
                            />
                        </svg>
                    }
                    
                />
            </div>
        </div>
    );
};

export default SearchArea;
